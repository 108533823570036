import React, { useState, useEffect } from "react"
import Row from "../Row"
import Section from "../Section"
import Container from "../Container"
import PortfolioEntry from "./PortfolioEntry"
import PortfolioModal from "./PortfolioModal"
import Title from "../Title"
import { useProjectsData } from "../../hooks/use-projects-data"
import Element from "../UI/Element"
import { Tab, Nav } from "react-bootstrap"
import { useCategoriesData } from "../../hooks/use-categories-data"
import styled from "styled-components"
import { theme } from "../../utils/styled_config"

const PortfolioSection = () => {
  const [projectDetails, setProjectDetails] = useState(
    "The Sheraton Cebu Mactan Resort"
  )
  const [selectedProject, setSelectedProject] = useState()
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const handleProjectClick = id => {
    setShow(true)
    setProjectDetails(id)
  }

  const categories = useCategoriesData()
  const allProjects = useProjectsData()

  const projectList = allProjects.sort((a, b) => (a.name < b.name ? -1 : 1))

  const featuredProjects = allProjects
    .filter(i => i.featured === true)
    .sort((a, b) => (a.project_id < b.project_id ? -1 : 1))
  useEffect(() => {
    setSelectedProject(allProjects.find(i => i.name === projectDetails))
  }, [projectDetails, allProjects, selectedProject])

  return (
    <Section>
      <Container>
        <Title size="1" lg="d2" mb={6} mbLg={10}>
          Featured Projects
        </Title>
        <Row className="portfolio__order">
          {featuredProjects &&
            featuredProjects.map(p => (
              <PortfolioEntry
                key={p.id}
                imgThumb={p.img_thumbnail}
                name={p.name}
                excerpt={p.excerpt}
                onClick={() => handleProjectClick(p.name)}
                projId={p.project_id}
              />
            ))}
        </Row>

        <Element
          height="1px"
          bg="white"
          width="100%"
          display="block"
          my={15}
        ></Element>
        <Title size="1" lg="d2" mb={6} mbLg={10}>
          List of Projects
        </Title>

        <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
          <TabNav>
            <Nav.Item>Filters By Category:</Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={0}>All Categories</Nav.Link>
            </Nav.Item>
            {categories.map(c => (
              <Nav.Item key={c.id}>
                <Nav.Link eventKey={c.category_id}>{c.name}</Nav.Link>
              </Nav.Item>
            ))}
          </TabNav>
          <Tab.Content>
            <Tab.Pane eventKey={0}>
              <Row>
                {projectList &&
                  projectList.map(p => (
                    <PortfolioEntry
                      key={p.id}
                      list={true}
                      imgThumb={p.img_thumbnail}
                      name={p.name}
                      description={p.description}
                      onClick={() => handleProjectClick(p.name)}
                      projectId={p.project_id}
                    />
                  ))}
              </Row>
            </Tab.Pane>
            {categories.map(c => (
              <Tab.Pane eventKey={c.category_id} key={c.id}>
                <Row>
                  {projectList
                    .filter(i => i.category_ids.indexOf(c.category_id) !== -1)
                    .map(p => (
                      <PortfolioEntry
                        key={p.id}
                        list={true}
                        imgThumb={p.img_thumbnail}
                        name={p.name}
                        description={p.description}
                        onClick={() => handleProjectClick(p.name)}
                      />
                    ))}
                </Row>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
        {selectedProject && (
          <PortfolioModal
            show={show}
            handleClose={handleClose}
            id={projectDetails}
            name={selectedProject.name}
            location={selectedProject.location}
            description={selectedProject.description}
            sliderImages={selectedProject.slider_imgs}
            soon={selectedProject.soon}
            tags={selectedProject.service_ids}
            // projId={selectedProject.project_id}
          ></PortfolioModal>
        )}
      </Container>
    </Section>
  )
}

const TabNav = styled(Nav)`
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  margin-bottom: 40px;
  font-family: "Marcellus", serif;
  color: #fff;
  justify-content: space-between;
  .active {
    color: ${theme.color.brand};
  }

  a {
    cursor: pointer;
  }
  .nav-item {
    font-size: 20px;
  }
`

export default PortfolioSection
