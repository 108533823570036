import React from "react"
import Seo from "../components/seo"
import BlurredSection from "../components/BlurredSection"
import PortfolioHero from "../components/portfolio/PortfolioHero"
import ContactLead from "../components/ContactLead"
import PortfolioSection from "../components/portfolio/PortfolioSection"

import "../stylesheets/tab.css"

const Portfolio = () => {
  return (
    <>
      <Seo title="Portfolio" />
      <BlurredSection>
        <PortfolioHero />
        <PortfolioSection />
        <ContactLead />
      </BlurredSection>
    </>
  )
}

export default Portfolio
