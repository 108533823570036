import React from "react"
import { useInView } from "react-intersection-observer"
import { motion } from "framer-motion"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { STAGGER, IMAGE_REVEAL } from "../../utils/animation"
import LineVector from "../LineVector"

import Col from "../Col"
import ImgReveal from "../ImgReveal"
import ArrowCta from "../ArrowCta"
import Title from "../Title"
import styled from "styled-components"
import { BREAKPOINTS } from "../../utils/styled_config"
import ScaledImage from "../ScaledImage"
import Paragraph from "../Paragraph"
import Element from "../UI/Element"
import theme from "../../utils/theme"

const PortfolioEntry = ({ name, imgThumb, onClick, excerpt, list, projId, projectId }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return (
    <ColStyled
      col={12}
      lg={4}
      as={motion.div}
      ref={ref}
      variants={STAGGER}
      initial="initial"
      animate={inView ? "animate" : "initial"}
      className={projectId ? `project--order-${projectId}` : `portfolio--order-${projId}`}
    >
      <ImgReveal variants={IMAGE_REVEAL}>
        <motion.div
          className="img-content"
          variants={IMAGE_REVEAL}
        ></motion.div>
        <Hover onClick={onClick}>
          <ScaledImage width={345} height={286}>
            <GatsbyImage image={getImage(imgThumb)} alt={name} />
          </ScaledImage>
        </Hover>
      </ImgReveal>
      {list ? (
        <TitleButton onClick={onClick}>
          <Title size={3}>{name}</Title>
        </TitleButton>
      ) : (
        <>
          <Title size={3} mt={5}>
            {name}
          </Title>
          <Element mt="-8px" mb={5}>
            <LineVector width={154.04} height={5.77} />
          </Element>
          <Paragraph>{excerpt || "---"}</Paragraph>
          <ArrowCta title="View Project" type="button" onClick={onClick} />
        </>
      )}
    </ColStyled>
  )
}

const ColStyled = styled(Col)`
  margin-bottom: 24px;

  @media screen and (min-width: ${BREAKPOINTS.lg}) {
    margin-bottom: 40px;
  }
  &.portfolio--order {
    &-31,
    &-1 {
      display: none;
    }
    &-7 {
      order: 1;
    }
    &-8 {
      order: 2;
    }
    &-12{
      order: 3;
    }
    &-13{
      order: 4;
    }
    &-17{
      order: 5;
    }
    &-18{
      order: 6;
    }
    &-22{
      order: 7;
    }
    &-23{
      order: 8;
    }
    &-26{
      order: 9;
    }
  }
  &.project--order {
    &-35 {
      display: none;
    }
    &-31 {
      order: 25;
    }
    &-5 {
      order: 1;
    }
    &-8 {
      order: 2;
    }
    &-3 {
      order: 3;
    }
    &-30 {
      order: 4;
    }
    &-29 {
      order: 5;
    }
    &-16 {
      order: 6;
    }
    &-6 {
      order: 7;
    }
    &-18 {
      order: 8;
    }
    &-23 {
      order: 9;
    }
    &-12 {
      order: 10;
    }
    &-19 {
      order: 11;
    }
    &-9 {
      order: 12;
    }
    &-15 {
      order: 13;
    }
    &-13 {
      order: 14;
    }
    &-25 {
      order: 15;
    }
    &-22 {
      order: 16;
    }
    &-21 {
      order: 17;
    }
    &-20 {
      order: 18;
    }
    &-27 {
      order: 19;
    }
    &-28 {
      order: 20;
    }
    &-17 {
      order: 21;
    }
    &-14 {
      order: 22;
    }
    &-2 {
      order: 23;
    }
    &-7 {
      order: 24;
    }
    &-1 {
      order: 26;
      width: 50%;
    }
    &-26 {
      order: 27;
      width: 50%;
    }
  }
`

const Hover = styled.div`
  .gatsby-image-wrapper {
    transition: ${theme.transition} !important;
  }
  &:hover {
    cursor: pointer;

    .gatsby-image-wrapper {
      transform: scale(1.2);
    }
  }
`

const TitleButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  color: #fff;
  cursor: pointer;
  margin-top: 12px;
  display: inline-block;
  text-align: left;
`

export default PortfolioEntry
